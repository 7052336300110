import {Component, OnDestroy, OnInit} from "@angular/core";
import {AvoirFournisseurDto} from "../../../core/dtos/facturation/fournisseurs/avoirs/avoir-fournisseur-dto";
import {AvoirService} from "../../../core/services/entities/avoir.service";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {ActivatedRoute} from "@angular/router";
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../core/models/is-deletable-object";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {NavigationService} from "../../../core/services/entities/navigation.service";
import {MailHistoDTO} from "../../../core/dtos/mail-histo-dto";

@Component({
  templateUrl: './avoir-fournisseur.component.html',
  styleUrls: ['./avoir-fournisseur.component.scss']
})
export class AvoirFournisseurComponent implements OnInit, OnDestroy {

  pathHelpFile: String = "";

  private subLog: Subscription;
  private subAvoirs: Subscription;
  private subRouteParams: Subscription;
  private subDelete: Subscription;
  private subAvoirUpdated: Subscription;

  avoirId: number;

  logged: boolean = false;

  routeOppened: boolean = false;
  selectedRows: number[] = [];
  avoirs: AvoirFournisseurDto[] = [];


  constructor(private avoirSvc: AvoirService,
              private auth2Svc: Auth2Service,
              private utilsSvc: UtilsService,
              private graphQlSvc: GraphQLService,
              private i8nSvc: InternationalizationService,
              private route: ActivatedRoute,
              private toastSvc: ToastService,
              private navigationSvc: NavigationService) {
  }

  ngOnInit(): void {
    this.initLoggedSubscription();
    this.initRouteSubscription();
    this.initAvoirsSubscription();
    this.initAvoirUpdatedSubscription()
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subLog);
    this.utilsSvc.unsubscribe(this.subAvoirs);
    this.utilsSvc.unsubscribe(this.subRouteParams);
    this.utilsSvc.unsubscribe(this.subDelete);
  }

  initLoggedSubscription = (): void => {
    this.subLog = this.auth2Svc.isLoggedIn$
      .subscribe(res => this.logged = res);
  }

  initAvoirsSubscription = (): void => {

    let queryName = "allAvoirsFournisseur";

    this.subAvoirs = this.graphQlSvc.sendQuery(`
      {
        ${queryName}(filters: {
          siteIds: [${this.auth2Svc.utilisateur.siteListLocaux.map(site => site.id)}]
          }) {
          ${AvoirFournisseurDto.getAllProperties()}
        }
      }
    `)
      .subscribe((response: any) => {
        this.avoirs = response[queryName];
        this.tryOpenAvoir();
      });
  }

  initRouteSubscription = (): void => {
    this.subRouteParams = this.route.queryParams.subscribe(params => {
      if (params.avoirId)
        this.avoirId = params.avoirId;
      this.tryOpenAvoir();
    });
  }

  initAvoirUpdatedSubscription = () => {
    this.subAvoirUpdated = this.avoirSvc.avoirUpdated$.subscribe(avoir => {
      this.avoirs[this.avoirs.findIndex(a => a.id == avoir.id)] = avoir;
    });
  }

  tryOpenAvoir = (): void => {
    if (this.routeOppened || !this.avoirId || !(this.avoirId && this.avoirs && this.avoirs.length > 0))
      return;

    let avoir: AvoirFournisseurDto = this.avoirs.find(a => a.id == this.avoirId);

    if (avoir) {
      this.routeOppened = true;
      this.openDetailsDialog(avoir);
    }
  }

  openDetailsDialog = (avoir: AvoirFournisseurDto): void => {
    this.avoirSvc.announceOpenDetailsDialog(avoir);
  }

  getStatusDisplayValue = (avoir: AvoirFournisseurDto): string => {
    let result = this.i8nSvc.getLabelFromCode("AVOIR_STATUS_" + avoir.statut, null);
    if (!result)
      return this.i8nSvc.getLabelFromCode("AVOIR_STATUS_UNKNOWN", null);
    return result;
  }

  // ATTENTION ! Laisser cette fonction sour le format nomDeFonction() {...} sinon cela ne fonctionne plus !
  getPrixDisplayValue(row: any): string {
    let prix = row[(this as any).dataField];
    if (prix)
      return prix?.value?.toFixed(4) + " " + prix?.uniteMonetaire;
  }

  deleteValues = async () => {
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.subDelete = this.avoirSvc.deleteAvoirsByFilters(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res && res.deletable) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `DELETION_COMPLETED_PLURAL` : `DELETION_COMPLETED_SINGULAR`, null));
        this.avoirs = this.avoirs.filter(avoir => !idsToDeleteInTable.find(id => id === avoir.id));
      }
    });
  };

  canDelete() {
    return this.selectedRows.length > 0;
  }

  navigateToFacture(avoir: AvoirFournisseurDto) {
    this.navigationSvc.navigateToFacture(avoir.facture.fournisseur.id, avoir.facture.numero);
  }

  onEmailHistoRretreived = (emailHisto: MailHistoDTO) => {
    this.avoirs.find(ligne => ligne.code === emailHisto.extraInfos).emailHisto.push(emailHisto);
  };

  getLastMail(emailHisto: any) {
    if (emailHisto)
      return emailHisto[emailHisto.length - 1];
    return null;
  }

  getFactureDate = (avoir: AvoirFournisseurDto) => {
    return this.utilsSvc.formatDate(avoir.facture.date);
  };
}
