import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {UtilsService} from "../../utils/utils.service";
import {AvoirFournisseurDto} from "../../dtos/facturation/fournisseurs/avoirs/avoir-fournisseur-dto";
import {HttpHeaders} from "@angular/common/http";
import {saveAs as fs_saveAs} from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class AvoirService {

  private subjectAvoirGenerated = new Subject<AvoirFournisseurDto>();
  avoirGenerated$ = this.subjectAvoirGenerated.asObservable();

  private subjectOpenDetailsDialog = new Subject<AvoirFournisseurDto>();
  openDetailsDialog$ = this.subjectOpenDetailsDialog.asObservable();

  private subjectAvoirUpdated = new Subject<AvoirFournisseurDto>();
  avoirUpdated$ = this.subjectAvoirUpdated.asObservable();

  constructor(private httpSvc: HttpService,
              private utilsSvc: UtilsService) {}

  generateAvoir = (idFacture: number) : Observable<ResponseWrapper<AvoirFournisseurDto>> => {
    const url = `dolrest/avoirs/generate?idFacture=${idFacture}`;
    return this.httpSvc.post(url, null);
  }

  announceAvoirGenerated = (avoir: AvoirFournisseurDto): void => {
    this.subjectAvoirGenerated.next(avoir);
}

  announceOpenDetailsDialog = (avoir: AvoirFournisseurDto) => {
    this.subjectOpenDetailsDialog.next(avoir);
  };

  deleteAvoirsByFilters = (rowsToDelete: number[]) => {
    let params: string = '?1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&idsAvoir=${rowsToDelete.join(',')}`
    return this.httpSvc.delete(`dolrest/avoirs/delete${params}`);
  };

  print(id: number) {
    this.httpSvc.get(`dolrest/avoirs/print?id=${id}`, null, 'blob', new HttpHeaders().set('Accept', 'application/pdf'))
      .subscribe(response => {
        const blob = new Blob([response], {type: 'application/pdf'});
        fs_saveAs(blob, `avoir-${new Date().getTime()}`);
      });
  }

  getAllStatus = () => this.httpSvc.get(`dolrest/avoirs/status/all`);

  edit = (avoir: AvoirFournisseurDto) => {
    let modified = {
      id: avoir.id,
      statut: avoir.statut,
      reference: avoir.reference,
      commentaire: avoir.commentaire,
      montantValide: avoir.montantValide
    }
    return this.httpSvc.post(`dolrest/avoirs/edit`, modified);
  };

  announceAvoirUpdated(avoir: AvoirFournisseurDto) {
    this.subjectAvoirUpdated.next(avoir);
  }

  sendMail = (avoirId: number): Observable<any> => {
    return this.httpSvc.post(`dolrest/avoirs/send`, avoirId);
  };

}
