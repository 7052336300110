import {interval, Observable, of, Subject} from 'rxjs';
import {ElementRef, Injectable} from '@angular/core';
import {JOURS_SEMAINES, JourSemaine, MSG_KEY, MSG_SEVERITY, MSG_STATUT} from '../constants';

import {MessageService, SelectItem, TreeNode} from 'primeng/api';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../suppliers/wrappers/response-wrapper';
import {Router} from '@angular/router';
import {FormGroup, NgForm} from '@angular/forms';
import * as moment from 'moment';
import 'moment/locale/fr';
import {ObjectDTO} from '../dtos/object-dto';
import {catchError, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ApplicationVersionDTO} from '../dtos/application-version-dto';
import {DOL_VERSION_JSON} from '../../../dol-version';
import * as FileSaver from 'file-saver';
import {
  capitalize as _capitalize,
  find as _find,
  parseInt as _parseInt,
  sortBy as _sortBy,
  uniqBy as _uniqBy
} from 'lodash';
import {ErreurFonctionnelleDto} from '../dtos/erreur-fonctionnelle-dto';
import {DialogMsgSupplier, Paragraphe} from '../suppliers/dialog-msg-supplier';
import {SiteDTO} from "../dtos/site-dto";
import {IsDeletableObject} from "../models/is-deletable-object";
import {InternationalizationService} from "../services/i8n/i8n.service";
import {CoreErrorModel} from "../models/technique/errors/core-error-model";
import {ToastService} from "../services/technique/toast.service";
import {AvoirFournisseurLigneDto} from "../dtos/facturation/fournisseurs/avoirs/avoir-fournisseur-ligne-dto";


export const URL_GET_APPLICATION_VERSION = `dolrest/version/getInfo`;
export const HEADER_HEIGHT_PX = 40;
export const FOOTER_HEIGHT_PX = 0;

@Injectable()
export class UtilsService {

  // utilisé dans toute l'application
  sidenav;


  detailError: DialogMsgSupplier;

  static UTILS: UtilsService;

  private _sidenavTertiary;
  private _mainContent: ElementRef;
  private _sidebar: ElementRef;
  private _mainContentHeight: number = 0;

  private subjectBlockui = new Subject<boolean>();
  subjectBlockui$ = this.subjectBlockui.asObservable();

  private subjectNotificationsInfos = new Subject();
  notificationsInfos$ = this.subjectNotificationsInfos.asObservable();

  private subjectAnnounceExpiredSession = new Subject<boolean>();
  expiredSession$ = this.subjectAnnounceExpiredSession.asObservable();
  private _headers;

  private subjectCanceledCalculConditionnement = new Subject();
  canceledCalculConditionnement$ = this.subjectCanceledCalculConditionnement.asObservable();

  constructor(private http: HttpClient,
              private router: Router,
              private msgSvc: MessageService,
              private titleSvc: Title,
              private i8nSvc: InternationalizationService,
              private toastSvc: ToastService) {
    UtilsService.UTILS = this;
  }

  /**
   * Récupération du chemin d'une icône de type logo se trouvant le répertoire assets/logo
   * @param iconName Nom de l'icône logo
   */
  getIconPath = (iconName: string): string => {
    const urlBase = document.getElementsByTagName("base");
    return urlBase ?
      `${window.location.origin}${urlBase[0].getAttribute("href")}assets/logo/${iconName}` :
      `${window.location.origin}assets/logo/${iconName};`
  }

  /**
   * L'argument doit avoir obligatoirement n° suivi de l'identifiant.
   * Sinon traitement manuellement IsDeletableObject
   * @param res IsDeletableObject
   * @param idsToDelete Identifiants à supprimer sélectionnés par l'utilisateur
   * @param list Liste d'éléments composant la grille d'origine contenant les éléments à supprimer
   */
  handleIsDeletableResultWithNum = (res: IsDeletableObject, idsToDelete: number[] = [], list: ObjectDTO[] = []): any[] => {
    if (!res) {
      return list.filter(e => !idsToDelete.includes(e.id));
    }
    if (res.deletable && (!res.messagesErrorList || !res.messagesErrorList.length))
      return list.filter(e => !idsToDelete.includes(e.id));
    else {
      const idsNotDeletable = res.messagesErrorList.map(e => {
        const args: string = e.args;
        const idxNum: number = args.indexOf("n°");
        if (idxNum > -1) {
          const idxId: number = idxNum + 2;
          return parseInt(args.substring(idxId, idxId + 1));
        }
        return null;
      }).filter(id => id !== null);
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, res.messagesErrorList.map(e => this.i8nSvc.getLabelFromCode(e.code, e.args.split(','))).join("\n"));

      if (idsNotDeletable && idsNotDeletable.length) {
        return list.filter(e => !idsToDelete.includes(e.id) || idsNotDeletable.includes(e.id));
      }
    }
  }

  closeSidenavs() {


    if (!this.isNullOrEmpty(this._sidenavTertiary)) {
      this._sidenavTertiary.close();
    }
  }

  announceExpiredSession(expired: boolean) {
    this.subjectAnnounceExpiredSession.next(expired);
  }

  announceSubjectBlockui(isBlocked: boolean) {
    this.subjectBlockui.next(isBlocked);
  }

  announceCanceledCalculConditionnement = () => {
    this.subjectCanceledCalculConditionnement.next();
  };


  /**
   * Gérer le titre de la page HTML
   * @param {string} straight
   * @param {string} dynamic
   */
  setTitle(straight: string, dynamic?: string) {

    if (straight) {
      if (dynamic) {
        this.titleSvc.setTitle(straight + ' ' + dynamic.toUpperCase());
      } else {
        this.titleSvc.setTitle(straight);
      }
    } else {
      this.titleSvc.setTitle('Datameal Online');
    }
  }

  isInputHidden(input: string): boolean {

    if (input && input !== '') {
      return false;
    }
    return true;
  }

  get headers() {
    return this._headers;
  }

  set headers(value) {
    this._headers = value;
  }


  get sidenavTertiary() {
    return this._sidenavTertiary;
  }

  set sidenavTertiary(value) {
    this._sidenavTertiary = value;
  }

  /**
   * Si Observable<Response> (data._body) on utilise .json() sinon on recup tel qel
   * @param data
   * @returns {any}
   */
  getResponseData(data: any): any {
    if (data._body) {
      return data.json();
    } else {
      return data;
    }
  }


  public sort(criterion: string, array: any[]): any[] {
    let tmpArr: any[] = [];
    if (array) {
      tmpArr = array.sort(function (a, b) {
        var nameA = a[criterion].toUpperCase(); // ignore upper and lowercase
        var nameB = b[criterion].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      return tmpArr;
    }
  }

  /**
   * Trier un tableau de SelectItem par l'attribut ordreSource asc
   * @param {SelectItem[]} list
   * @returns {SelectItem[]}
   */
  public sortByOrder(list: SelectItem[]): SelectItem[] {
    let items: SelectItem[] = [];

    list.map(
      (item, index) => {
        item.value.ordre = index + 1;
        items.push(item);
      }
    )

    //items = list.sort((n1, n2) => n1.value.ordreSource - n2.value.ordreSource);
    return items;
  }

  /**
   * Récupérer le libellé à partir de l'id dans uns liste de SelectItem
   * @param {SelectItem[]} arr
   * @param {number} id
   * @returns {string}, vide si rien trouvé
   */
  public getSelectedItemLabel(arr: SelectItem[], id: number): string {
    if (arr) {
      for (let o of arr) {
        if (o.value == id) {
          return o.label;
        }
      }
    }
    return '';
  }

  notYetImplemented(msg?: string) {

    let msgResult = 'PAS ENCORE IMPLÉMENTÉ';
    if (!this.isNullOrEmpty(msg)) {
      msgResult += ' : ' + msg;
    }

    window.alert(msgResult);
  }

  unsubscribe(sub: any) {
    if (sub) {
      sub.unsubscribe();
    }
  }

  /**
   * Renvoyer une reponse en erreur
   * @param errorMsg
   * @returns {Observable<ResponseWrapper<T>>}
   */
  handleResponseError<T>(errorMsg, displayMsg: boolean = false): Observable<ResponseWrapper<T>> {
    let response: ResponseWrapper<T> = new ResponseWrapper();
    response.resultMessage = errorMsg;
    response.inError = true;
    if (displayMsg) this.toastSvc.displayToast(MSG_KEY.ROOT_ERROR, MSG_SEVERITY.ERROR, response.resultMessage);
    return of(response);
  }

  private getErrorFromResponse(error: HttpErrorResponse): string {
    if (error.error && error.error.resultMessage) {

      return error.error.resultMessage;
    }
    return error.statusText;
  }

  /***
   * On check si une erreur est renvoyée dans le response supplier. Si oui, on notifie l'utilisateur
   * @param response
   * @returns {boolean}
   */
  isResponseSupplierError(response: ResponseWrapper<any>): boolean {
    if (this.isNullOrEmpty(response)) {
      return true;
    } else if (response instanceof HttpErrorResponse) {

      this.handleError(response, true);
      return true;

    } else if (response && response.inError) {
      if (this.isErrorMetier(response)) {
        return false;
      } else {
        if (response.resultList && response.resultList.length && !response.resultMessage) {
          const errorsList: string[] = response.resultList
            .map((error: CoreErrorModel) => this.i8nSvc.getLabelFromCode(error.code, error.args.split(',')));
          const errorsStr = errorsList.join('<br/>');
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, errorsStr);
        } else if (response.resultMessage != null) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, response.resultMessage);
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'ERREUR');
        }

        return true;
      }
    }
    return false;
  }

  /**
   *    palliatif pour les reponses qui sont en erreur mais qui utilisent le additionalProperties['erreurs']
   * dans ce cas, on veut les traiter dans la reponse et pas comme une erreur interne
   */
  isErrorMetier(response) {

    if (response.additionalProperties &&
      response.additionalProperties['erreurs'] &&
      response.additionalProperties['erreurs'].length > 0) {
      return true;
    }

    return false;

  }

  handleError(error: any, displayMsg: boolean = false): Observable<any> | Promise<any> {
    let err = {msg: '', status: ''};

    if (error) {
      if (error.error && error.error.resultList && error.status != 403) {
        const errorsList: string[] = error.error.resultList
          .map((error: CoreErrorModel) => this.i8nSvc.getLabelFromCode(error.code, error.args.split(',')));
        const errorsStr = errorsList.join('<br/>');
        err = {
          msg: errorsStr,
          status: MSG_STATUT.ERROR
        };
      } else {
        if (error.status === 400) {
          if (error.error && error.error.error === 'invalid_grant') {
            err = {
              msg: `Utilisateur non reconnu.`,
              status: MSG_STATUT.ERROR_400
            };
            this.announceExpiredSession(true);
          } else {
            // Erreur bizarre
            console.log('-------------------------------------------');
            console.log('error.status = ' + error.status);
            console.log('error.message = ' + error.message);
            console.log('-------------------------------------------');

            err = {
              msg: `L'application envoie une requete incompréhensible par le service DATAMEAL API : ${this.getErrorFromResponse(error)}`,
              status: MSG_STATUT.ERROR_400
            };
          }
        }
        if (error.status === 401) {

          err = {
            msg: `Login ou mot de passe inconnu.`,
            status: MSG_STATUT.ERROR_401
          };

          // , rediriger vers la fenetre de login --> service auth2 est abonné pour faire un logout
          this.announceExpiredSession(true);
        }
        if (error.status === 403) {
          this.announceExpiredSession(true);
        }
        if (error.status === 404) {
          err = {
            msg: `Le service DATAMEAL API est introuvable.`,
            status: MSG_STATUT.ERROR_404
          };
        }
        if (error.status >= 500) {
          err = {
            msg: `Le service DATAMEAL API est en erreur`,
            status: MSG_STATUT.ERROR_500
          };
        }
        if (error.status === 503) {
          err = {
            msg: `Le service DATAMEAL API est momentanement indisponible.`,
            status: MSG_STATUT.ERROR_503
          };
        }
        if (error.status === 504) {
          err = {
            msg: `Le service DATAMEAL API ne répond pas correctement.`,
            status: MSG_STATUT.ERROR_504
          };
        }
      }

      // si p, doit afficher le message
      if (displayMsg && err.msg !== '')
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, err.msg);
    }
    return of(error);
  }

  showDetailError(code: string, msg: string, description: string) {

    const dialogDetail = new DialogMsgSupplier();
    dialogDetail.title = code;
    dialogDetail.logo = 'fa fa-cogs  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = msg;
    p1.lines = [description];

    dialogDetail.content = {
      intro: ``,
      paragraphes: [p1]
    };


    return dialogDetail;

  }

  fillItemsWithId(jsonContent): SelectItem[] {
    let items: SelectItem[] = [];
    if (jsonContent) {
      for (let entry of jsonContent) {
        // console.log(entry.libelle);
        items.push({label: entry.libelle, value: entry.id});
      }
    }
    return items;
  }

  isIdInit(id: any) {
    return id !== null && id !== undefined && id > 0
  }

  isNullOrEmpty(value: any): boolean {

    let response: boolean = UtilsService.staticIsNullOrEmpty(value);
    return response;
  }

  static staticIsNullOrEmpty(value: any): boolean {

    if (value == null || value == undefined || value == 'undefined' || value === '') {
      return true;
    }
    return false;
  }

  isCollectionNullOrEmpty(value: any[]): boolean {
    let response: boolean = UtilsService.staticIsCollectionNullOrEmpty(value);
    return response;
  }


  /**
   * Indique si le tableau passé en paramètre est <b>null</b> ou vide.
   * @param value
   */
  static staticIsCollectionNullOrEmpty(value: any[]): boolean {
    // Si la collection est null ou égale à undefined
    if (this.staticIsNullOrEmpty(value)) {
      return true;
    }

    // Si la collection est vide.
    if (value.length == 0) {
      return true;
    }
    return false;
  }

  /**
   * Est ce que c'est un nombre, est il non nul et supérieur à 0
   */
  isNumberGt0(value: any) {
    if (!this.isNullOrEmpty(value) && value > 0) {
      return true;
    }
    return false;
  }


  /**
   * Renvoie le <b>number</b>, transformation de la <b>string</b> passée en paramètre
   * @param {string} codeCouleur une <b>string</b> représentant une couleur (par exemple '#01ABEF').
   * @returns {number}
   */
  public toNumber(codeCouleur: string): number {
    if (codeCouleur == undefined) {
      return 0;
    }
    return _parseInt(codeCouleur.substr(1), 16);
  }


  getHexColorFromString(codeCouleur: string, sharpPrefix: boolean) {
    return sharpPrefix ? '#' + codeCouleur : codeCouleur;
  }

  /**
   * Renvoie une <b>string</b> représentant une couleur en fonction de la couleur passsée ne paramètre.
   * Exemple : codeCouleur = 109551( 01ABEF en hexadécimal), valeur renvoyée : #01ABEF
   * @param {number} codeCouleur l'entier représentant une couleur.
   * @returns {string}
   */
  public getHexColor(codeCouleur: number): string {

    // Valeur par défaut.
    let defaultValue = '#000001';

    // codeCouleur indefini : on renvoie la valeur par défaut.
    if (codeCouleur == undefined) {
      return defaultValue;
    }

    // codeCouleur négatif : on renvoie la valeur par défaut.
    if (codeCouleur < 1) {
      return defaultValue;
    }

    // Transformation en string
    let asString = codeCouleur.toString(16);

    // Complétion par des zéros
    while (asString.length < 6) {
      asString = '0' + asString;
    }

    // Mise de '#' en préfix
    let response = '#' + asString;


    return response;
  }

  public isNullColor(codeCouleur: number): boolean {
    if (codeCouleur > 0) {
      return false;
    }
    return true;
  }


  /**
   * https://github.com/angular/material2/issues/8848
   * reset de l'etat du formulaire reactif avec les composants material design
   *
   * @param ngForm
   * @param value valeurs à positionner dans le formulaire reactif
   */
  public resetStateForm(ngForm: NgForm, value: any) {

    if (!this.isNullOrEmpty(ngForm)) {
      ngForm.resetForm(value);
    }
  }


  getFirstElement(arr: any[]): any {
    if (!this.isCollectionNullOrEmpty(arr)) {
      return arr[0];
    }
    return undefined;
  }

  /**
   * Permet de savoir si une scrollbar verticale est affichée dans l'element
   * @param {ElementRef} eltRef
   * @returns {boolean}
   */
  isVerticalScrollBarVisible(eltRef: any): boolean {

    if (!this.isNullOrEmpty(eltRef)) {
      return eltRef.clientHeight < eltRef.scrollHeight;
    }
    return false;
  }


  /**
   * Déterminer la couleur de fond d'un element selon son type
   * @param {string} bgColor
   * @param {string} expected
   * @returns {boolean}
   */
  isBg(bgColor: string, expected: string): boolean {

    if (bgColor === expected) {
      // console.log('bgCol',bgColor);
      return true;
    }
    return false;
  }


  trackByFn(index, item) {
    return index; // or item.id
  }

  /**
   * Positionner l'heure sur une date
   */
  setHour(date: Date, hours: number, minutes: number = 0, seconds: number = 0) {


    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    return date;
  }

  /**
   * Afficher un nombre avec son pluriel ou son singulier
   * @param label
   * @param totalRecords
   * @return {string}
   */
  getTotalRecordsLabel(label: string, totalRecords: number) {

    let ttRecords = 0;
    if (this.isNumberGt0(totalRecords)) {
      ttRecords = totalRecords;
    }

    if (totalRecords > 1 && label) {
      const words: string[] = label.split(' ');
      const pluralWords: string[] = [];
      for (let word of words) {
        if (word.toLowerCase() === 'de'
          || word.toLowerCase() === 'gemrcn') {
          pluralWords.push(word);
        } else {

          if (!word.endsWith('s')) {
            word += 's';
          }

          pluralWords.push(word);
        }
      }
      return `${ttRecords} ${this.capitalize(pluralWords.join(' '))}`;
    }

    return `${ttRecords} ${this.capitalize(label)}`;

  }


  getSlots(length: number): number[] {

    let slots: number[] = [];

    for (let i = 0; i < length; i++) {
      slots.push(i);
    }

    return slots;
  }

  /**
   * renvoyer la largeur d'un datagrid kjlnhjk en fullpage
   * @returns {string}
   */
  getPrimeNgDatagridWidthStyle() {


    if (window.innerWidth > 1024) {

      if (window.innerWidth > 1300) {
        if (window.innerWidth > 1900) {
          return {'width': '1610px'};
        }
        return {'width': '1000px'};
      }

      return {'width': '900px'};

    }


    return {'width': '500px'};


  }

  capitalize(label: string): string {

    return _capitalize(label);
  }

  /**
   *
   * @param {number} timestamp
   *
   * renvoie une date au format DD/MM/YYYY HH:mm
   */
  getFrenchDateHHMM(timestamp: number): string {

    return moment(timestamp).clone().format('DD/MM/YYYY à HH:mm');

  }

  getDateDDMMYYYY(date: Date) {
    const padStart = (value: number): string => value.toString().padStart(2, '0');
    return `${padStart(date?.getDate())}/${padStart(date?.getMonth() + 1)}/${date?.getFullYear()}`;
  }


  /**
   * Récupérer un jour semaine par son id
   * id= 1 => Lundi
   * id = 7 => Dimanche
   * @param idJourSemaine
   */
  getJourSemaineById(idJourSemaine: number): JourSemaine {

    const elt = _find(JOURS_SEMAINES, {'value': idJourSemaine});

    return elt ? elt : undefined;
  }


  /**
   * Ecrire une date sous forme Lun 6 Sep 2018
   * @param {moment.Moment} momentDay
   * @returns {string}
   */
  getFrenchDate_ddd_ll(momentDay: moment.Moment | number | Date): string {

    if (!moment.isMoment(momentDay)) {
      momentDay = moment(momentDay);
    }

    moment.locale('fr');
    const inner = momentDay.clone();

    return this.capitalize(inner.format('ddd ll'));
  }

  convertYYYYMMdd = (time: number | Date): string => {
    return this.commonYYYYMMdd(typeof time === 'number' ? new Date(time) : time);
  }

  private commonYYYYMMdd(dateJs: Date) {
    let month = dateJs.getMonth() + 1;
    let day = dateJs.getDate();
    return `${dateJs.getFullYear()}${month < 10 ? '0' + month : month}${day < 10 ? '0' + day : day}`;
  }

  convertddMMYYYYSlashVersion = (time: number): string => {
    let dateJs = new Date(time);
    let month = dateJs.getMonth() + 1;
    let day = dateJs.getDate();
    return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${dateJs.getFullYear()}`;
  }

  /**
   * Ecrire une date sous forme Lundi 6 Septembre 2018
   * @param {moment.Moment} momentDay
   * @returns {string}
   */
  getFrenchDate_dddd_Do_MMMM_YYYY(momentDay: moment.Moment | number): string {

    moment.locale('fr');


    if (!moment.isMoment(momentDay)) {
      momentDay = moment(momentDay);
    }

    const inner = momentDay.clone();

    return this.capitalize(inner.format('dddd Do MMMM YYYY'));
  }

  getFrenchDateDDMMYYYY(momentDay: moment.Moment): string {

    moment.locale('fr');

    const inner = momentDay.clone();

    return inner.format('DD/MM/YYYY');
  }

  formatToDDMMYYYY = (date: number): string => {
    if (!date) return '';

    const d = new Date(date);
    let month: number = d.getMonth() + 1;
    let strMonth: string = month < 10 ? `0${month}` : `${month}`;
    let dayOfMonth: string = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`;
    let fullYear: number = d.getFullYear();

    return `${dayOfMonth}/${strMonth}/${fullYear}`;
  }

  dateToDDMMYYYY = (date: Date): string => {
    if (typeof date === 'number') {
      return this.formatToDDMMYYYY(date);
    }
    return moment(date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
  }

  /**
   * @deprecated Utiliser à la place convertYYYYMMdd
   * @param momentDay
   */
  getYYYYMMDD(momentDay: moment.Moment): string {

    const inner = momentDay.clone();

    return inner.format('YYYYMMDD');
  }

  /**
   * Si 2 dates sont identiques au format YYYYMMDD, on retourne vrai
   * @param {moment.Moment} date1
   * @param {moment.Moment} date2
   * @returns {boolean}
   */
  isDateEqualsYYYYMMDD(date1: moment.Moment, date2: moment.Moment) {
    const date1Str = this.getYYYYMMDD(date1);
    const date2Str = this.getYYYYMMDD(date2);

    return date1Str === date2Str;
  }

  convertToTimestamp(dateString: string): number {
    return moment(dateString, 'DD/MM/YYYY HH:mm:ss').valueOf();
  }

  transformStringToDate = (dateString): Date => {
    return moment(dateString, 'DD/MM/YYYY HH:mm:ss').toDate();
  };

  /**
   * Recupérer le nombre d'élement dune collection de DTO
   * @param {ObjectDTO[]} objects
   */
  getTotalRecords(objects: ObjectDTO[]) {
    let totalRecords = 0;
    if (!this.isCollectionNullOrEmpty(objects)) {
      totalRecords = objects.length;
    }

    return totalRecords;
  }


  get mainContent(): ElementRef {
    return this._mainContent;
  }

  set mainContent(value: ElementRef) {
    this._mainContent = value;
  }

  get sidebar(): ElementRef {
    return this._sidebar;
  }

  set sidebar(value: ElementRef) {
    this._sidebar = value;
  }

  /**
   * Calculer la hauteur du contenu principal
   * @returns {string}
   */
  getMainContentHeight() {

    if (this.mainContent && this._mainContentHeight === 0) {

      this._mainContentHeight = this.mainContent.nativeElement.offsetHeight;
    }

    // console.log('HAUTEUR CONTENU PRINCIPAL', this._mainContentHeight);

    return this._mainContentHeight + 'px';
    // return '866px';
  }

  getWindowAvailableHeight(delta: number) {
    let height = window.innerHeight - delta;
    return height;
  }


  getWindowAvailableWidth(delta: number) {
    let width = window.innerWidth - delta;
    return width;
  }

  getScreenAvailableWidth(delta: number) {

    let width = window.screen.availWidth - delta;


    return width;
  }

  getScreenAvailableHeight(delta: number) {

    let height = window.screen.availHeight - delta;


    return height;
  }


  /**
   * methode utilitaire pour utiliser un *ngFor à partir d'un nombre
   * @param nbTimes
   */
  arrayOne(nbTimes: number): number[] {
    const arr: number[] = [];

    for (let i = 1; i <= nbTimes; i++) {
      arr.push(i);
    }

    return arr;
  }

  /**
   * Filtrer un tableau selon une propriété
   * @param arr
   * @param property
   *
   * @return renvoie un nouveau tableau,... vide si pas d'element.
   */
  filter(arr: any[], property: string) {

    if (this.isCollectionNullOrEmpty(arr)) {
      return [];
    }

    const arrFiltered = arr.filter(item => item[property] === true);

    if (this.isCollectionNullOrEmpty(arrFiltered)) {
      return [];
    }

    return arrFiltered;
  }

  /**
   * Générer une liste de couleur à partir d'une couleur de départ et d'une couleur de fin
   * @param startColor format hexa #xxxxxx
   * @param endColor format hexa #xxxxxx
   * @param steps nombre de couleurs à générer
   */
  gradient(startColor, endColor, steps) {
    let start = {
      'Hex': startColor,
      'R': _parseInt(startColor.slice(1, 3), 16),
      'G': _parseInt(startColor.slice(3, 5), 16),
      'B': _parseInt(startColor.slice(5, 7), 16)
    }
    let end = {
      'Hex': endColor,
      'R': _parseInt(endColor.slice(1, 3), 16),
      'G': _parseInt(endColor.slice(3, 5), 16),
      'B': _parseInt(endColor.slice(5, 7), 16)
    }
    let diffR = end['R'] - start['R'];
    let diffG = end['G'] - start['G'];
    let diffB = end['B'] - start['B'];

    let stepsHex = new Array();
    let stepsR = new Array();
    let stepsG = new Array();
    let stepsB = new Array();

    for (var i = 0; i <= steps; i++) {
      stepsR[i] = start['R'] + ((diffR / steps) * i);
      stepsG[i] = start['G'] + ((diffG / steps) * i);
      stepsB[i] = start['B'] + ((diffB / steps) * i);
      stepsHex[i] = '#' + Math.round(stepsR[i]).toString(16) + '' + Math.round(stepsG[i]).toString(16) + '' + Math.round(stepsB[i]).toString(16);
    }
    return stepsHex;

  }

  /**
   *  prselectionner une liste à choix mulitple à partir d'un ensemble d'elements
   * @param elements
   * @param selectedElements elements selectionné
   * @return {any[]}
   */
  preSelectMultiList<T extends ObjectDTO>(elements: T[], selectedElements: T[]): T[] {
    const arr = [];

    if (!this.isCollectionNullOrEmpty(selectedElements)) {

      for (let item of elements) {
        const elt = _find(selectedElements, ['id', item.id]);
        if (elt) {
          arr.push(item);
        }
      }
    }
    return arr;
  }

  preSelectMultiListPair(elements: any[], selectedElements: any[]): any[] {
    const arr = [];

    if (!this.isCollectionNullOrEmpty(selectedElements)) {
      for (let item of elements) {
        const elt = _find(selectedElements, ['value', item.value]); //selectedElements.find(se => se.value === elt.value);
        if (elt) {
          arr.push(item);
        }
      }
    }

    return arr;
  }

  /**
   * Préselection dans une liste à choix simple
   * @param elements
   * @param selectedElement
   * @return{T | undefined}
   */
  preSelectSingleList<T extends ObjectDTO>(elements: T[], selectedElement: T): T {

    // console.log('this.preSelectSingleList()',elements);

    // Si ni selectedElement, ni elements n'est null
    if (!this.isNullOrEmpty(selectedElement) && !this.isCollectionNullOrEmpty(elements)) {

      // si la dropdown ne contient qu'un seul élément, on le préselectionne
      if (elements.length === 1) {
        return elements[0];
      }

      // On cherche selectedElement dans le tableau elements
      for (let elt of elements) {
        if (elt.id === selectedElement.id) {
          return elt;
        }
      }
    } else {
      if (!this.isCollectionNullOrEmpty(elements)) {
        if (elements.length === 1) {
          return elements[0];
        }
      }
    }

    return null;
  }

  /**
   * Indique si un élément appartient à un tableau.
   * @param array le tableau concerné
   * @param element l'élément concerné.
   */
  contains<T extends ObjectDTO>(array: T[], element: T): boolean {
    if (!this.isNullOrEmpty(element) && !this.isNullOrEmpty(array)) {

      // On cherche element dans le tableau array
      for (let elt of array) {
        if (elt.id === element.id) {
          return true;
        }
      }
      return false;

    }
    return false;
  }

  /**
   * Notre version
   */
  flatDeep(arr, d = 1) {
    return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val), []) : arr.slice();
  };

  /**
   *  prselectionner une liste de jours semaines à partir d'item
   * @param elts
   * @param list
   * @return {any[]}
   */
  preSelectJourSemaine(elts: JourSemaine[], list: JourSemaine[]): JourSemaine[] {
    const arr = [];

    if (!this.isCollectionNullOrEmpty(list)) {

      for (let item of list) {
        const elt = _find(elts, {'value': item.value});
        if (elt) {
          arr.push(item);
        }
      }
    }
    return arr;
  }


  /**
   * Renvoyer une collection sans doublon et triée
   * @param elts
   * @param uniqPredicate
   * @param sortPredicate
   */
  uniqAndSort<T extends ObjectDTO>(elts: T[], uniqPredicate: string, sortPredicate: string): T[] {

    let arr = [];
    if (!this.isCollectionNullOrEmpty(elts)) {
      arr = _uniqBy(elts, uniqPredicate);
      arr = _sortBy(arr, sortPredicate);
    }

    return arr;
  }

  /**
   * Rechercher la prochaine instance de date d'un jour semaine
   * @param date pivot
   * @param weekdayINeed
   */
  findNextInstanceOfWeekday(date: Date, weekdayINeed: number) {

    const momentDate = moment(date).clone();
    const weekDay = momentDate.isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (weekDay <= weekdayINeed) {

      // then just give me this week's instance of that day
      return momentDate.isoWeekday(weekdayINeed);

    } else {

      // otherwise, give me *next week's* instance of that same day
      return momentDate.add(1, 'weeks').isoWeekday(weekdayINeed);
    }

  }

  /**
   * Alimenter un tableau {key, value}
   *  @param backData
   * @returns {any[]}
   */
  initArray<T extends ObjectDTO>(backData: T[]): { key, value }[] {
    let response: { key, value }[] = UtilsService.staticInitArray(backData);
    return response;
  }

  static staticInitArray<T extends ObjectDTO>(backData: T[]): { key, value }[] {

    let data = [];
    if (!UtilsService.staticIsCollectionNullOrEmpty(backData)) {
      backData.map(udm => {

        const value = udm;
        if (value instanceof ObjectDTO && !UtilsService.staticIsNullOrEmpty(value.libelle)) {
          value.libelle = value.libelle.toUpperCase();
        }

        data.push({key: udm.id, value});
      });

    }

    data = _sortBy(data, 'value.libelle');

    return data;
  }

  /**
   * Convertir une date au format nombre en une date de type Date
   * @param numberDate
   */
  convertNumberDateToDate(numberDate: number | Date, initNow: boolean = true): Date {


    if (this.isNullOrEmpty(numberDate)) {
      if (initNow) {
        return new Date();
      } else {
        return undefined;
      }

    }

    return moment(numberDate).toDate();
  }

  /**
   * Convertir une quantite dans une unité de mesure vers une autre unité de mesure
   * @param quantiteUdm1    quantite en en unite de mesure 1
   * @param ratioUdm1Kg ratio udm1 / unite de base
   * @param ratioUdm2Kg ratio udm2 / unite de base
   * @return quantite en en unite de mesure 2
   */
  convertQuantiteUdm1ToQuantiteUdm2(quantiteUdm1: number, ratioUdm1Kg: number, ratioUdm2Kg: number) {

    let quantiteUdmBase;
    let quantiteUdm2;

    // quantité unite de base (au kg)
    quantiteUdmBase = quantiteUdm1 * ratioUdm1Kg;

    // quantité en unite de mesure 2
    quantiteUdm2 = quantiteUdmBase / ratioUdm2Kg;

    // console.log('ratioUdm1, ratioUdm2, quantiteUdm1n quantiteUdm2', ratioUdm1Kg, ratioUdm2Kg, quantiteUdm1, quantiteUdm2);

    // fix probleme d'arrondi
    quantiteUdm2 = parseFloat(quantiteUdm2.toFixed(4));

    return quantiteUdm2;

  }

  /**
   * Convertir une prix unitaire dans une unité de mesure vers une autre unité de mesure
   * @param prixUnitaireUdm1    quantite en en unite de mesure 1
   * @param ratioUdm1Kg ratio udm1 / unite de base
   * @param ratioUdm2Kg ratio udm2 / unite de base
   * @return prix unitaire en unite de mesure 2
   */
  convertPrixUnitaireUdm1ToPrixUnitaireUdm2(prixUnitaireUdm1: number, ratioUdm1Kg: number, ratioUdm2Kg: number) {

    let prixUnitaireUdmBase;
    let prixUnitaireUdm2;

    // prix unitaire de base (au kg)
    prixUnitaireUdmBase = prixUnitaireUdm1 / ratioUdm1Kg;

    // prix unitaire en unite de mesure 2
    prixUnitaireUdm2 = prixUnitaireUdmBase * ratioUdm2Kg;

    // console.log('ratioUdm1, ratioUdm2, prixUnitaireUdm1 prixUnitaireUdm2', ratioUdm1Kg, ratioUdm2Kg, prixUnitaireUdm1, prixUnitaireUdm2);

    // fix probleme d'arrondi
    prixUnitaireUdm2 = parseFloat(prixUnitaireUdm2.toFixed(4));

    return prixUnitaireUdm2;

  }


  isDateOk(date: Date, joursSemaine: number[]): boolean {

    const jsDateFour = moment(date).isoWeekday();

    if (!this.isCollectionNullOrEmpty(joursSemaine)) {

      const jourExists = _find(joursSemaine, function (o) {
        return o === jsDateFour
      });

      return jourExists ? true : false;
    }

    return false;
  }


  isDateBefore(date: Date): boolean {
    const now = moment();
    return moment(date).isBefore(now);
  }


  /**
   * Lorsqu'on utilise les fleches du clavier on peut renseigner un input text
   * @param $event
   * @param goeZero greater or equals 0, si false on peut saisir des nombre negatifs
   * @param value valeur à modifier
   */
  keydownNumber($event: KeyboardEvent, data: any | FormGroup, property: string, goeZero = true) {

    const key = $event.key;
    // console.log('keydownNumber', $event);

    if (data instanceof FormGroup) {

      if (key === 'ArrowUp') {

        let val = +data.controls[property].value + 1;
        data.controls[property].setValue(val);


      } else if (key === 'ArrowDown') {

        if (goeZero) {
          if (data.controls[property].value >= 1) {

            let val = +data.controls[property].value - 1;
            data.controls[property].setValue(val);

          } else {
            data.controls[property].setValue(0);
          }

        } else {

          let val = +data.controls[property].value - 1;
          data.controls[property].setValue(val);

        }

      }

    } else {

      if (key === 'ArrowUp') {

        data[property]++;

      } else if (key === 'ArrowDown') {

        if (goeZero) {
          if (data[property] >= 1) {

            +data[property]--;
          } else {
            data[property] = 0;
          }

        } else {
          +data[property]--;
        }
      }
    }
  }


  updateRowGroupMetaData(rows: any[], groupPropertyName: string): any {

    let rowGroups = {};

    if (rows) {
      for (let i = 0; i < rows.length; i++) {
        let rowData = rows[i];
        let groupPropertyValue = rowData[groupPropertyName];
        if (i == 0) {
          rowGroups[groupPropertyValue] = {index: 0, size: 1};
        } else {
          let previousRowData = rows[i - 1];
          let previousRowGroup = previousRowData[groupPropertyName];
          if (groupPropertyValue === previousRowGroup) {
            rowGroups[groupPropertyValue].size++;
          } else {
            rowGroups[groupPropertyValue] = {index: i, size: 1};
          }
        }
      }
    }

    return rowGroups;
  }

  deleteMessage(response: ResponseWrapper<IsDeletableObject>, componentTarget: string) {
    if (response.one.deletable) {
      this.toastSvc.displayToast(componentTarget, MSG_SEVERITY.SUCCESS, `Suppression réussie`);
    } else {
      const errorsList: string[] = response.one.messagesErrorList
        .map((error: CoreErrorModel) => this.i8nSvc.getLabelFromCode(error.code, error.args ? error.args.split(',') : []));
      const errorsStr = errorsList.join('\n');
      this.toastSvc.displayToast(componentTarget, MSG_SEVERITY.WARNING, errorsStr);
    }
  }

  selectedNodesLevel(selectedNodes: TreeNode[], niveau: number): TreeNode[] {

    let nodes = [];

    if (!this.isCollectionNullOrEmpty(selectedNodes)) {

      nodes = selectedNodes.filter(node => node.data.niveau === niveau);

    }
    return nodes;

  }

  /**
   * Déplier les noeuds d'un arbre de manière récursive
   * @param node
   * @param isExpand
   * @param limit niveau max à déplier
   */
  expandRecursive(node: TreeNode, isExpand: boolean, limit: number) {

    node.expanded = isExpand;
    // console.log('expandRecursive');

    if (node.children) {

      node.children.forEach(childNode => {
        if (childNode.data.niveau <= limit) {
          this.expandRecursive(childNode, isExpand, limit);
        }

      });
    }

  }

  /**
   * Sélectionner les noeuds d'un arbre de manière récursive
   * @param node
   * @param selectedNodes
   */
  selectRecursive(node: TreeNode, selectedNodes: TreeNode[]) {

    if (this.isCollectionNullOrEmpty(selectedNodes)) {
      selectedNodes = [];
    }

    selectedNodes.push(node);

    if (node.children) {
      node.children.forEach(childNode => this.selectRecursive(childNode, selectedNodes));
    }

    return selectedNodes;

  }

  /**
   * Récupérer les dates entre une date de fin et une date de début
   * @param startDate
   * @param stopDate
   */
  getDates(startDate: Date, stopDate: Date) {

    const dates = [];
    let mCurrentDate = moment(startDate);
    const mStopDate = moment(stopDate);
    while (mCurrentDate <= mStopDate) {
      dates.push(moment(mCurrentDate).format('YYYY-MM-DD'));
      mCurrentDate = moment(mCurrentDate).add(1, 'days');
    }

    return dates;
  }

  safe<T extends ObjectDTO>(resultList: T[] | any) {

    if (this.isCollectionNullOrEmpty(resultList)) {
      return [];
    }

    return resultList;
  }

  formatDate(date) {
    if (date)
      if (typeof date == "string") {
        let currentFormat: string = this.findDateFormat(date);
        return this.customFormatDate(date, currentFormat, "DD/MM/yyyy");
      } else
        return this.formatToDDMMYYYY(date.getTime());
    return null;
  }

  formatDateTime(date) {
    if (date)
      if (typeof date == "string") {
        let currentFormat: string = this.findDateFormat(date);
        return this.customFormatDate(date, currentFormat, "DD/MM/yyyy HH:mm:SS");
      } else
        return this.formatToDDMMYYYY(date.getTime());
    return null;
  }

  findDateFormat(date: string): string {
    let formats = ["DD/MM/yyyy", "DD/MM/yyyy HH:mm:SS"];

    for (const format of formats) {
      let regex: string = this.convertFormatToRegex(format);
      if (date.match(regex))
        return format;
    }
  }

  convertFormatToRegex(format: string): string {
    format = format.replace("DD", "[0-3][0-9]");
    format = format.replace("MM", "[0-1][0-9]");
    format = format.replace("yyyy", "[0-9]{4}");

    format = format.replace("HH", "[0-2][0-9]");
    format = format.replace("mm", "[0-5][0-9]");
    format = format.replace("SS", "[0-5][0-9]");

    return format;
  }

  /**
   *
   * @param dateString
   * @param inputFormat format d'entree
   * @param outputFormat format de sortire
   */
  customFormatDate(dateString: string, inputFormat: string, outputFormat: string) {

    const date = moment(dateString, inputFormat);

    return moment(date).format(outputFormat);

  }

  getApplicationVersion() {
    return this.http.get(URL_GET_APPLICATION_VERSION).pipe(
      catchError(err => this.handleError(err)));
  }

  /**
   * Est ce que la version de datameal online est à jour
   * @param versionFromBackend
   */
  isVersionToDate(versionFromBackend: ApplicationVersionDTO) {

    const jsonVersionFromBackend = JSON.parse(versionFromBackend.version);

    // on compare la version du back et du front
    return jsonVersionFromBackend.longHash === DOL_VERSION_JSON.longHash;
  }


  /**
   *  si la version datameal online n'est pas à jour, on essaie de la mettre à jour
   */
  updateApplicationVersion() {
    this.getApplicationVersion().subscribe(response => {
      if (!this.isResponseSupplierError(response)) {
        const appVersion: ApplicationVersionDTO = response.one;
        if (!this.isVersionToDate(appVersion))
          this.subjectNotificationsInfos.next({message: `Une nouvelle version de Datameal Online est disponible.<br/><br/> Appuyez sur le bouton OK pour l'obtenir.`});
      }
    });
  }

  isDateAfter(dateToCompare: Date, datePivot: Date) {

    const m1 = moment(dateToCompare);
    const m2 = moment(datePivot);

    return m1.isAfter(m2, 'day');

  }

  isDateAfterOrEquals(dateToCompare: Date, datePivot: Date) {

    const m1 = moment(dateToCompare);
    const m2 = moment(datePivot);

    return m1.isSameOrAfter(m2, 'day');

  }

  /**
   * Vérifier qu'une date est présente dans une liste de date
   * @param date
   * @param dateList
   */
  isDateInList(date: Date, dateList: Date[]): boolean {
    // console.log('isDateInList ',date);
    //console.log(date)
    //console.log(dateList)
    if (!this.isCollectionNullOrEmpty(dateList)) {
      for (let mcDate of dateList) {

        if (this.isDateEqualsYYYYMMDD(moment(mcDate), moment(date))) {

          return true;
        }
      }
    }
    return false;
  }

  /**
   * Arroundi le nombre au nombre de décimal souhaité
   * @param number
   * @param dec
   */
  public roundFloat(number, decimal) {
    let d: any = 1;
    for (let i = 0; i < decimal; i++) {
      d += '0';
    }
    return Math.round(number * d) / d;
  }

  /**
   * renvoyer les jours semaines non disponibles
   * Méthode utilitaire pour les calendriers kjlnhjk
   * @param joursDisponibles
   */
  getDisabledDays(joursDisponibles: number[]): number[] {
    // console.log('get disabled days');

    const disabledDays = [];
    for (let i = 1; i <= 7; i++) {
      const jourExists = _find(joursDisponibles, function (o) {
        return o === i
      });

      if (!jourExists) {

        // le dimanche est le 0 dans les disabledDays, contrainte p-calendar kjlnhjk
        if (i === 7) {
          disabledDays.push(0);
        } else {
          disabledDays.push(i);
        }

      }
    }

    // console.log( joursSemaines,disabledDays);

    return disabledDays;
  }


  /**
   * Ajouter une list de propriétés undefined a un object
   * @param obj
   * @param properties
   */
  public addPropertyList(obj: any, properties: string[]) {

    properties.forEach((prop: string) => {
      Object.defineProperty(obj, prop, {
        value: undefined,
        writable: true,
        enumerable: true,
        configurable: true
      });
    });
  }

  /**
   * Ajouter une liste de propriété a un object a la list de value associé a ces propriétés
   * @param obj
   * @param properties
   * @param value
   */
  public addPropertyListAndValueList(obj: any, properties: string[], value: any[]) {
    for (let i = 0; i < properties.length; i++) {
      Object.defineProperty(obj, properties[i],
        {
          value: value.length > i ? value[i] : undefined,
          writable: true,
          enumerable: true,
          configurable: true
        });
    }
  }

  /**
   * Export le buffer dans un fichier xlsx
   * @param buffer
   * @param fileName
   */
  public saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.ms-excel';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  log(content: any) {
    console.log('log', content);
  }

  removePx(numberPx: string): number {
    return +numberPx.substring(0, numberPx.length - 2);
  }


  /**
   * un p-dialog est constitué d'un header, d'un content et d'un footerw<br>
   *     @return hauteur max du body
   */
  getMaxHeightBodyDialog() {

    let height = window.innerHeight - 150;

    if (height > 950) {
      height = 950;
    }

    return height;
  }


  getMaxWidthBody() {

    let width = window.innerWidth - 300;

    if (width > 1000) {
      width = 1000;
    }

    return width;

  }

  getErreur(erreurList: ErreurFonctionnelleDto[], codeErreur: string) {

    if (!this.isCollectionNullOrEmpty(erreurList)) {

      const err = _find(erreurList, {'codeErreur': codeErreur});
      if (err) {
        return err;
      }
    }

    return null;
  }

  /**
   * Mettre le focus sur un element HTML
   * @param idElement attribut id de l'élément HTML
   * @param maxNbRetries nombre max de tentatives de mise en place du focus
   * @param intervalMs interval entre chaque tentative en millisecondes
   */
  focus(idElement: string, maxNbRetries: number, intervalMs: number) {

    let nbRetries = 0;
    let elt = null;
    const stop$ = new Subject<boolean>();
    const source = interval(intervalMs);
    const source$ = source.pipe(
      tap(item => {
        elt = document.getElementById(idElement);
        nbRetries++;
        if (nbRetries > maxNbRetries) {
          stop$.next(true);
          console.log(`impossible de mettre le focus sur l'élément !`)
        }
      }),
      filter(item => !this.isNullOrEmpty(elt)),
      map(item => {
        elt.focus();
        stop$.next(true);
      }),
      takeUntil(stop$)
    ).subscribe();
  }

  retryInterval(maxNbRetries: number = 4, intervalMs: number = 10000, taskObservable: Observable<any>) {
    let nbRetries = 0;
    const stop$ = new Subject<boolean>();
    const source = interval(intervalMs);
    return source.pipe(
      switchMap(data => {
          ++nbRetries;
          if (nbRetries > maxNbRetries) {
            stop$.next(true);
          }
          return taskObservable;
        }
      ),
      catchError(err => this.handleError(err, true)),
      takeUntil(stop$)
    );
  }

  onChangeDisabledInputChips(event) {
    if (!(event.keyCode == 8 || event.keyCode == 46)) {
      return false;
    }
  }


  /**
   * Calculer dynamiquement la taille que doit avoir un dialog sur l'écran
   * @param delta somme des marges que l'on veut des 2 côtes de l'écran
   * @param maxWidth taille maximale du dialog
   * @param screenWidthComplete taille à partir de laquelle on estime que l'affichage est optimal. Si taille de l'ecran > à  screenWidthComplete, la taille du dialog sera egale à maxWidth
   */
  getDialogWidth(delta: number, maxWidth: number, screenWidthComplete: number) {

    const innerWidth = window.innerWidth;

    if (innerWidth > screenWidthComplete) {
      return maxWidth;
    }

    return innerWidth - delta;
  }

  /**
   * Calcule la hauteur disponible en enlevant le delta et les HEADERS / FOOTERS de la page
   * @param deltaPx
   */
  scrollHeightContent(deltaPx = 0) {

    let size = HEADER_HEIGHT_PX + FOOTER_HEIGHT_PX + deltaPx;
    const calc = `calc(100vh - ${size}px)`;

    return calc;
  }

  /**
   * Calculer la hauteur disponible d'un dialog en enlevant le delta
   * @param heightPx
   * @param deltaPx
   */
  scrollHeightDialog(heightPx: number, deltaPx: number) {
    const size = heightPx - deltaPx;
    return size + 'px';
  }

  /**
   * permet de tranformet un SiteDTO en un SiteObjectDN acceptable par la serialization  FRONT- BACK
   * @param site
   */
  getLightSite(site: SiteDTO) {
    return {
      libelle: site.libelle,
      actif: site.actif,
      reference: site.reference,
      id: site.id,
      idUniteDeProduction: site.idUniteDeProduction
    }

  }

  /**
   * Supprime les tags html pour du pur texte
   * @param html html à convertir en texte
   * @return texte
   */
  stripeHtml(html: string): string {
    if (!html) return '';
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  convertDayOfWeekFrenchToString: (dayOfWeekFrench: number) => (string) = (dayOfWeekFrench: number) => {
    switch (dayOfWeekFrench) {
      case 1:
        return 'Lundi';
      case 2:
        return 'Mardi';
      case 3:
        return 'Mercredi';
      case 4:
        return 'Jeudi';
      case 5:
        return 'Vendredi';
      case 6:
        return 'Samedi';
      case 7:
        return 'Dimanche';
      default:
        return 'Lundi';
    }
  };

  /**
   * Arrondir la valeur avec un arrondi
   * @param value Valeur calculée à arrondir
   * @param arrondi Valeur du nombre de décimal que l'on veut
   */
  roundTo = (value, arrondi) => +(Math.round(Number(value + "e+" + arrondi)) + "e-" + arrondi);

  getValidMontant(quantity, conditionnePar: number) {
    if (conditionnePar && conditionnePar > 0 && quantity % conditionnePar != 0)
      quantity = Math.ceil(quantity / conditionnePar) * conditionnePar;
    return quantity;
  }

  getFormattedFileName = (fileName: String) => {
    return fileName.replace(/[^a-zA-Z0-9\\.\\-]/g, "_");
  }

  modulo(a, b) {
    const mod = a % b;
    return mod.toFixed(10) == b ? 0 : mod;
  }
}

interface Week {
  weekInYear: number;
  weekMonday: Date;
  weekSunday: Date;
  weekYear: number;
}

