<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help lang="fr" [file]="pathHelpFile" [width]="800"></yo-help>
    </div>
  </dxi-item>
  <dxi-item location="after" class="mg-r-16">
    <yo-button
      iconClass="fas fa-trash"
      buttonClass="cta-delete"
      (onClick)="deleteValues()"
      [disabled]="!canDelete()"
      tooltip="Supprimer les éléments sélectionnés">
    </yo-button>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="avoirs"
  keyExpr="id"
  class="data-grid"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  columnResizingMode="widget"
  noDataText="Pas de données"
  [(selectedRowKeys)]="selectedRows"
  loadPanel="true"
  #grid>
  <dxo-selection
    selectAllMode="allPages"
    showCheckBoxesMode="always"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions"
              [width]="79"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column caption="Statut"
              cellTemplate="avoirStatutTemplate"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="facture.site.libelle" caption="Site"
              [filterOperations]="['contains']"
              cellTemplate="siteTemplate"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="code" caption="Numéro d'avoir"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="facture.fournisseur.libelle" caption="Fournisseur"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="facture.numero" caption="Facture"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="factureTemplate">
  </dxi-column>
  <dxi-column dataField="facture.date" caption="Date facture"
              [calculateDisplayValue]="getFactureDate"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="reference" caption="Référence"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="montant" caption="Montant"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              [calculateDisplayValue]="getPrixDisplayValue"
              alignment="right">
  </dxi-column>
  <dxi-column dataField="montantValide" caption="Montant validé"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              [calculateDisplayValue]="getPrixDisplayValue"
              alignment="right">
  </dxi-column>
  <dxi-column dataField="commentaire" caption="Commentaire"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button
      (yoNavigation)="openDetailsDialog(cell.row.data)"
      [yoTooltipShowDelay]="500"
      [yoSmall]="true"
      yoTextAlign="center"
      yoIconClass="fas fa-edit"
      yoTooltip="Consulter l'avoir">
    </yo-cell-button>
  </div>

  <div class="avoir-statut" *dxTemplate="let cell of 'avoirStatutTemplate'">
    <span>{{getStatusDisplayValue(cell.row.data)}}</span>
    <yo-email-histo-button
      [code]="cell.row.data.code"
      [emailHisto]="getLastMail(cell.row.data.emailHisto)"
      (onMailHistoRetreive)="onEmailHistoRretreived($event)">
    </yo-email-histo-button>
  </div>

  <div *dxTemplate="let cell of 'siteTemplate'">
    <yo-site [siteLibelle]="cell.row.data?.facture?.site?.libelle" [siteId]="cell.row.data?.facture?.site?.id"></yo-site>
  </div>

  <div *dxTemplate="let cell of 'factureTemplate'">
    <yo-button buttonClass="cta-link" [label]="cell.data.facture.numero" (onClick)="navigateToFacture(cell.row.data)"></yo-button>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} avoirs">
  </dxo-pager>

</dx-data-grid>

<yo-avoirFournisseurDetailsDialog></yo-avoirFournisseurDetailsDialog>

<yo-dialog-histo-mails></yo-dialog-histo-mails>
