// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]7.0-11496(a348510b25)-C03/04/2025-21:18:31-B03/04/2025-21:56:23' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]7.0-11496(a348510b25)-C03/04/2025-21:18:31-B03/04/2025-21:56:23",
  branch: "production",
  latestTag: "7.0",
  revCount: "11496",
  shortHash: "a348510b25",
  longHash: "a348510b25e643fe65e43f5bae36b81652bc378a",
  dateCommit: "03/04/2025-21:18:31",
  dateBuild: "03/04/2025-21:56:23",
  buildType: "Ansible",
  } ;
